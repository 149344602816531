<template>
    <div>
        <jy-query ref="form" :model="form">
            <jy-query-item label="线路:" prop="routeId" label-width="70px">
                <!-- <el-input placeholder="请选择" v-model="form.routeName" @focus="xlselectInstitutions"></el-input> -->
                <el-select v-model="form.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="vehicleNo" label="车牌号:">
                <el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
            </jy-query-item>
            <jy-query-item prop="registerType" label="业务登记类型:" label-width="120px">
                <el-select v-model="form.registerType" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="查询日期:" prop="time" :span="1.5">
                <el-date-picker v-model="form.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true"></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('reset')">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="exportList" v-if="btnexist('export')">导出</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="routeNo" label="线路编号"></jy-table-column>
            <jy-table-column prop="routeName" label="线路名称"></jy-table-column>
            <jy-table-column prop="orgName" label="所属机构"></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号"></jy-table-column>
            <jy-table-column prop="deviceNo" label="设备ID"></jy-table-column>
            <jy-table-column prop="driverName" label="司机姓名"></jy-table-column>
            <jy-table-column prop="workerId" label="司机工号"></jy-table-column>
            <jy-table-column prop="registerType" label="业务登记类型"></jy-table-column>
            <jy-table-column prop="time" label="登记时间"></jy-table-column>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <xlinstitutions-tree ref="xlinstitutions" @addInstitutions="xladdInstitutions" url="/waybillOperate/getTree" title="选择线路" :defaultProps="props"></xlinstitutions-tree>
    </div>
</template>
<script>
import xlinstitutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            pickerOptions: {
                // disabledDate(time) {
                //     return time.getTime() > Date.now() - 8.64e7
                // },
            },

            props: {
                children: 'children',
                label: 'text',
                value: 'id',
            },
            form: {
                routeName: '',
                routeId: '',
                vehicleNo: '',
                registerType: '',
                time: [],
            },
            pageSize: 10,
            total: 0,
            pageIndex: 1,
            // 列表列数据
            dataList: [],

            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    value: '1',
                    label: '发车',
                },
                {
                    value: '2',
                    label: '进场',
                },
            ],

            routeOptions:[],

            btnRoute:'/admin/detailData/businessRegistrationDetail',
        }
    },
    mixins:[btnMixins],
    components: {
        xlinstitutionsTree,
    },
    created() {
        this.getList()
    },
    activated(){
        this.getRouteList()
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            if (option.time != null && option.time.length) {
                option.startDate = option.time[0]
                option.endDate = option.time[1]
            } else {
                option.startDate = ''
                option.endDate = ''
            }
            let url = '/stat/BusinessReg/queryPage'
            // console.log(option)
            this.$http.post(url, option).then(res => {
                this.dataList = res.detail.list
                this.total = res.detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.form.routeId = ''
            this.oncheck()
        },
        // 选择线路树
        xlselectInstitutions() {
            this.$refs.xlinstitutions.init()
        },
        // 上级线路机构查询
        xladdInstitutions(data) {
            // console.log(data)
            this.form.routeId = data.id
            this.form.routeName = data.text
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        //导出
        exportList() {
            let url = '/stat/BusinessReg/export'
            let option = {
                ...this.form,
            }
            if (option.time != null && option.time.length) {
                option.startDate = option.time[0]
                option.endDate = option.time[1]
            } else {
                option.startDate = ''
                option.endDate = ''
            }
            this.$http
                .post(url, option, { type: 'format', isExcel: true })
                .then(res => {
                    this.$util.funDownload(res.data, '业务登记明细')
                })
        },
    },
}
</script>
